import { PrimaryButton, Seo, Text } from 'components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import SMS from '../../assets/icons/sms.svg';
import Location from '../../assets/icons/location.svg';
import Home from '../../assets/icons/home.svg';
import { config } from 'config';
import { CircularProgress } from '@material-ui/core';
import Header from 'components/reviews/Header';
import Footer from 'components/reviews/Footer';
import LCFooter from 'components/LCFooter';
import { useQuizData } from 'utils/hooks';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const disclaimer = useQuizData('shortDisclaimer');
  const data = useQuizData('contact');
  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.honeypot === '') {
      sendEmail();
    } else {
      setError(true);
    }
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      await fetch(config.FIREBASE_MAILING, {
        method: 'POST',
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          message: formData.message,
          funnelCode: 'EF',
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setFormData({
        name: '',
        email: '',
        message: '',
        honeypot: '',
      });
      setError(false);
      setSuccess(true);
    } catch {
      setSuccess(false);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Seo
        title="Contact Us"
        description="Leave a message and get in touch with us"
        name="Contact Us"
      />

      <Header isMobileApp={false} />

      <ContactSection>
        <LeftSide>
          <LeftSideTitle>
            {data?.leftTitle || 'Our contact details'}
          </LeftSideTitle>
          <LeftSideWrapper>
            <Column>
              <ItemWrap>
                <TextWrap>
                  <SmsLogo />
                  <Title>{data?.email || 'Email address'}</Title>
                </TextWrap>
                <LeftSubTitle>help@effecto.app</LeftSubTitle>
              </ItemWrap>
              <ItemWrap>
                <TextWrap>
                  <HomeLogo />
                  <Title>{data?.company || 'Company'}</Title>
                </TextWrap>
                <LeftSubTitle>Medical Score UAB</LeftSubTitle>
              </ItemWrap>
              <ItemWrap>
                <TextWrap>
                  <HomeLogo />
                  <Title>{data?.code || 'Company code'}</Title>
                </TextWrap>
                <LeftSubTitle>305445861</LeftSubTitle>
              </ItemWrap>
            </Column>
            <Column>
              <ItemWrap>
                <TextWrap>
                  <LocationLogo />
                  <Title>{data?.address || 'Address of registration'}</Title>
                </TextWrap>
                <LeftSubTitle>
                  J. Zemgulio str. 46, Panevezys, Lithuania
                </LeftSubTitle>
              </ItemWrap>
              <ItemWrap>
                <TextWrap>
                  <LocationLogo />
                  <Title>{data?.address2 || 'Address'}</Title>
                </TextWrap>
                <LeftSubTitle>
                  Antakalnio str. 17, Vilnius, Lithuania
                </LeftSubTitle>
              </ItemWrap>
            </Column>
          </LeftSideWrapper>
        </LeftSide>
        <RightSide>
          <RightSideTitle>
            {data?.rightTitle || 'Contact customer support'}
          </RightSideTitle>
          <form onSubmit={handleSubmit}>
            <StyledLabel>
              {data?.yourEmail || 'Email'} <StyledStar>*</StyledStar>
            </StyledLabel>
            <HoneyPot
              name="honeyPot"
              onChange={updateInput}
              value={formData.honeyPot || ''}
            />
            <StyledInput
              type="email"
              name="email"
              placeholder={data?.emailPlaceholder || 'Please enter your email'}
              required={true}
              onChange={updateInput}
              value={formData.email || ''}
            />

            <StyledLabel>
              {data?.subject || 'Subject'} <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledInput
              type="text"
              name="name"
              placeholder={data?.subjectPlaceholder || 'Please enter a subject'}
              required={true}
              onChange={updateInput}
              value={formData.name || ''}
            />

            <StyledLabel>
              {data?.desc || 'Description'} <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledTextArea
              name="message"
              onChange={updateInput}
              placeholder={data?.descPlaceholder || 'Your message'}
              value={formData.message || ''}
            />

            <Note>
              {data?.note ||
                'Please enter the details of your request. A member of our team will respond as soon as possible.'}
            </Note>
            {error && (
              <ErrorText>
                {data?.error || 'There was an issue sending your email.'}
              </ErrorText>
            )}
            {success && (
              <SuccessText>
                {data?.success || 'Message was sent successfully!'}
              </SuccessText>
            )}
            <StyledButton disabled={loading ? true : false} type="submit">
              {loading ? (
                <CircularProgress style={{ color: '#fff' }} size={22} />
              ) : (
                data?.buttonTitle || 'Send'
              )}
            </StyledButton>
          </form>
        </RightSide>
      </ContactSection>
      <LCFooter
        disclaimerText={
          disclaimer || ['© ${year} Effecto. All rights reserved.']
        }
      />
    </>
  );
};

export default Contact;

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
  margin-top: 69px;

  a {
    font-family: 'Poppins', sans-serif;
  }
`;

const ContactSection = styled.div`
  display: flex;
  margin: 0 1rem;
  column-gap: 96px;
  margin: auto;
  max-width: 100%;
  padding: 3.5rem 3rem;
  max-width: 86rem;
  width: 100%;
  min-height: calc(100vh - 72px - 148px);
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;

  h1,
  p,
  div,
  label,
  input,
  textarea {
    color: #000;
  }

  label,
  input,
  input::placeholder,
  div,
  textarea {
    font-family: 'Poppins', sans-serif;
  }

  @media ${tablet} {
    padding: 36px 16px;
    flex-direction: column-reverse !important;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 50%;

  @media ${tablet} {
    width: 100%;
  }
`;

const LeftSideTitle = styled(Text)`
  color: #000;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.038rem;

  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 130%;
    letter-spacing: -0.024rem;
  }
`;

const HomeLogo = styled(Home)`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
`;

const LocationLogo = styled(Location)`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
`;

const SmsLogo = styled(SMS)`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
`;

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.25rem;
`;

const Title = styled(Text)`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.018rem;
  /* margin-bottom: 4px; */
`;

const LeftSubTitle = styled(Text)`
  color: #28293d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.014rem;
  padding-left: 2.25rem;
`;

const RightSide = styled.div`
  width: 50%;
  border-radius: 0.5rem;
  border: 1px solid rgba(28, 28, 40, 0.08);
  background: var(--Effecto-new-Light-Beige, #f9f6f4);
  padding: 1rem;
  height: fit-content;

  @media ${tablet} {
    width: 100%;
    margin-bottom: 4rem;
  }
`;

const RightSideTitle = styled(Text)`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.024rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 120%;
    letter-spacing: -0.02rem;
  }
`;

const HoneyPot = styled.input`
  display: none;
`;

const StyledStar = styled.span`
  color: #dc4b4b;
`;

const SuccessText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 12px;
  color: rgb(6, 194, 112) !important;
`;

const ErrorText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  text-align: center;
  color: rgb(255, 59, 59) !important;
`;

const Note = styled.div`
  color: #555770;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.012rem;
  margin: 0.75rem 0 1rem;
`;

const StyledButton = styled(PrimaryButton)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;

  @media ${tablet} {
    /* margin-bottom: 36px; */
  }

  p {
    color: #fff !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.016rem;
  }
`;

const StyledLabel = styled.label`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.014rem;
  margin-bottom: 0.25rem;
`;

const StyledInput = styled.input`
  border-radius: 0.5rem;
  border: 1px solid rgba(28, 28, 40, 0.08);
  background: #fff;
  padding: 0 1rem;
  height: 3rem;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.014rem;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  -webkit-appearance: none;
  outline: none;

  &::placeholder {
    color: #8e909a !important;
  }
`;

const StyledTextArea = styled.textarea`
  border-radius: 0.5rem;
  border: 1px solid rgba(28, 28, 40, 0.08);
  background: #fff;
  padding: 0.875rem 1rem;
  height: 3rem;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.014rem;
  display: block;
  width: 100%;
  min-height: 9rem;
  outline: none;

  &::placeholder {
    color: #8e909a !important;
  }
`;

const LeftSideWrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  @media ${tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
